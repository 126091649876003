'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useDebounceCallback } from '@react-hook/debounce'
import { useMutation } from '@tanstack/react-query'
import { useSearchParams } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { FormInput } from '@/components/core/FormInput'
import { FormPassword } from '@/components/core/FormPassword'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useRouter } from '@/i18n/routing'
import { env } from '@/utils/envs'
import { defaultToastError } from '@/utils/etc'
import * as fetch from '@/utils/fetch'

const FormSchema = z.union([
  z.object({
    method: z.literal('magiclink'),
    email: z.string().email({
      message: {
        'pt-br': 'Email inválido',
        'en-us': 'Invalid email',
      }[env.LANGUAGE],
    }),
  }),
  z.object({
    method: z.literal('password'),
    email: z.string().email({
      message: {
        'pt-br': 'Email inválido',
        'en-us': 'Invalid email',
      }[env.LANGUAGE],
    }),
    password: z.string().min(6, {
      message: {
        'pt-br': 'Senha deve conter no mínimo 6 caracteres',
        'en-us': 'Password must contain at least 6 characters',
      }[env.LANGUAGE],
    }),
  }),
])

export function LoginForm() {
  const t = useTranslations('LoginForm')
  const { push } = useRouter()
  const searchParams = useSearchParams()
  const callbackUrl = searchParams?.get('callbackUrl') || '/projects'

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
    },
  })
  const authenticationMethod = form.watch('method')
  const email = form.watch('email')

  // Get isSubmitting from formState
  const { isSubmitting } = form.formState

  const { mutateAsync: loginWithEmailAndPassword } = useMutation({
    mutationFn: async (values: { email: string; password: string }) => {
      return await fetch
        .portal('/auth/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...values }),
        })
        .then(({ data }) => data)
    },
    onSuccess: () => {
      push(callbackUrl as never)
    },
    onError: (err: Error) => {
      if (err?.message !== 'challenge-closed') {
        defaultToastError(err)
      }
    },
  })

  const { mutateAsync: loginWithMagicLink } = useMutation({
    mutationFn: async (values: { email: string }) => {
      return await fetch
        .portal('/auth/magiclink', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...values,
            destination: 'email',
            redirectTo: callbackUrl,
          }),
        })
        .then(({ data }) => data)
    },
    onSuccess: () => {
      push('/login/magiclink')
    },
    onError: defaultToastError,
  })

  const fetchLoginMethod = useDebounceCallback(
    async ({ email }: { email: string }) => {
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) return
      return await fetch
        .portal<{ method: 'magiclink' | 'password' }>('/auth/method', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        })
        .then(({ data }) => {
          form.setValue('method', data.method)
        })
        .catch(() => {
          form.setValue('method', 'magiclink')
        })
    }
  )

  useEffect(() => {
    fetchLoginMethod({ email })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    try {
      if (data.method === 'password') {
        await loginWithEmailAndPassword(data)
      } else {
        await loginWithMagicLink(data)
      }
    } catch (error) {
      defaultToastError(error)
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full px-6 sm:px-0 sm:w-2/3 space-y-4"
      >
        <FormInput control={form.control} name="email" label={t('email')} />
        {authenticationMethod === 'password' && (
          <FormPassword
            control={form.control}
            name="password"
            label={t('password')}
          />
        )}
        <Button
          className="w-full !mt-6 disabled:opacity-100"
          type="submit"
          loading={isSubmitting}
        >
          {t('loginBtn')}
        </Button>
      </form>
    </Form>
  )
}
